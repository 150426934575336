import {
  useRouter as useNextRouter,
  usePathname as useNextPathname,
  useSearchParams as useNextSearchParams,
  useParams as useNextParams,
  useServerInsertedHTML as useNextServerInsertedHTML,
} from 'next/navigation';
import { useMemo } from 'react';
import { pathForInternalLink } from './pathForInternalLink';
import { shouldRouteInternally } from './shouldRouteInternally';

/**
 * Get the current parameters. For example useParams() on /dashboard/[team] where pathname is /dashboard/nextjs would return { team: 'nextjs' }
 * https://nextjs.org/docs/app/api-reference/functions/use-params
 */
export const useParams: typeof useNextParams = () => {
  return useNextParams();
};

/**
 * Get the current pathname including basePath. For example usePathname() on /app/dashboard?foo=bar would return "/app/dashboard".
 * https://nextjs.org/docs/app/api-reference/functions/use-pathname
 */
export function usePathname() {
  const BASE_PATH =
    process.env.NEXT_PUBLIC_BASE_PATH === '<empty>'
      ? ''
      : process.env.NEXT_PUBLIC_BASE_PATH;

  return `${BASE_PATH}${useNextPathname()}`;
}

/**
 * Get a read-only URLSearchParams object. For example searchParams.get('foo') would return 'bar' when ?foo=bar
 * Learn more about URLSearchParams here:
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * https://nextjs.org/docs/app/api-reference/functions/use-search-params
 *
 * This object is nullable as long as we have the /pages directory.
 */
export function useSearchParams() {
  return useNextSearchParams();
}

export function useServerInsertedHTML(callback: () => React.ReactNode): void {
  return useNextServerInsertedHTML(callback);
}

export type MentimeterRouter = {
  /**
   * Navigates to a page within the application. Handles both client side and between-app cases.
   * @param href - The abolute path to the destination, e.g. /app/dashboard
   */
  push: (href: string) => void;
  /**
   * Navigates to a page within the application, without adding the URL to the history stack. Handles both client side and between-app cases.
   * @param href - The abolute path to the destination, e.g. /app/dashboard
   */
  replace: (href: string) => void;
} & Omit<ReturnType<typeof useNextRouter>, 'push' | 'replace'>;

export function useRouter(): MentimeterRouter {
  const nextRouter = useNextRouter();

  const BASE_PATH =
    process.env.NEXT_PUBLIC_BASE_PATH === '<empty>'
      ? ''
      : process.env.NEXT_PUBLIC_BASE_PATH;

  if (BASE_PATH === undefined) {
    throw new Error('@mentimeter/next-navigation is missing base path env var');
  }

  return useMemo(
    () => ({
      ...nextRouter,
      push: (href: string): void => {
        const routeInternally = shouldRouteInternally(BASE_PATH, href);

        if (routeInternally) {
          const pushUrl = pathForInternalLink(BASE_PATH, href);

          return nextRouter.push(pushUrl);
        }

        window.location.assign(href);
      },
      replace: (href: string): void => {
        const routeInternally = shouldRouteInternally(BASE_PATH, href);

        if (routeInternally) {
          const replaceUrl = pathForInternalLink(BASE_PATH, href);

          return nextRouter.replace(replaceUrl);
        }

        window.location.replace(href);
      },
      refresh: () => {
        nextRouter.refresh();
      },
    }),
    [BASE_PATH, nextRouter],
  );
}
