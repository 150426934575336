import { alpha } from './utils';

// Black Alpha ✨
export const blackBase = '#000000';
export const blackAlpha50 = alpha(blackBase, 0.02);
export const blackAlpha100 = alpha(blackBase, 0.05);
export const blackAlpha200 = alpha(blackBase, 0.1);
export const blackAlpha300 = alpha(blackBase, 0.14);
export const blackAlpha400 = alpha(blackBase, 0.2);
export const blackAlpha500 = alpha(blackBase, 0.3);
export const blackAlpha600 = alpha(blackBase, 0.45);
export const blackAlpha700 = alpha(blackBase, 0.55);
export const blackAlpha800 = alpha(blackBase, 0.65);
export const blackAlpha900 = alpha(blackBase, 0.75);
export const blackAlpha1000 = alpha(blackBase, 0.85);

// White Alpha ✨
export const whiteBase = '#ffffff';
export const whiteAlpha50 = alpha(whiteBase, 0.02);
export const whiteAlpha100 = alpha(whiteBase, 0.05);
export const whiteAlpha200 = alpha(whiteBase, 0.1);
export const whiteAlpha300 = alpha(whiteBase, 0.14);
export const whiteAlpha400 = alpha(whiteBase, 0.2);
export const whiteAlpha500 = alpha(whiteBase, 0.3);
export const whiteAlpha600 = alpha(whiteBase, 0.45);
export const whiteAlpha700 = alpha(whiteBase, 0.55);
export const whiteAlpha800 = alpha(whiteBase, 0.65);
export const whiteAlpha900 = alpha(whiteBase, 0.75);
export const whiteAlpha1000 = alpha(whiteBase, 0.85);

// Gray ✨
export const grayBase = '#DEDCD9';
export const gray50 = '#FCFBF9';
export const gray100 = '#F7F6F4';
export const gray200 = '#F2F1F0';
export const gray300 = '#EBEAE8';
export const gray400 = '#E8E6E3';
export const gray500 = grayBase;
export const gray600 = '#C5C3C1';
export const gray700 = '#A09F9D';
export const gray800 = '#747371';
export const gray900 = '#5D5B59';
export const gray1000 = '#474543';
export const gray1050 = '#363432';
export const gray1100 = '#302E2C';
export const gray1200 = '#252321';
export const gray1300 = '#171717';
export const gray1400 = '#101010';

// Blue ✨
export const blueBase = '#3947A4';
export const blue50 = '#202126';
export const blue100 = '#24252D';
export const blue200 = '#222743';
export const blue300 = '#28305F';
export const blue400 = '#2B3893';
export const blue500 = blueBase;
export const blue600 = '#4D59AB';
export const blue700 = '#6C75C2';
export const blue800 = '#8088C0';

// Blue Alpha ✨
const blueAlphaBase = '#5769E7';
export const blueAlpha50 = alpha(blueAlphaBase, 0.25);
export const blueAlpha100 = alpha(blueAlphaBase, 0.35);
export const blueAlpha500 = alpha(blueAlphaBase, 0.5);
export const blueAlpha700 = alpha(blueAlphaBase, 0.6);
export const blueAlpha900 = alpha(blueAlphaBase, 0.9);

// Green ✨
export const greenBase = '#5BA472';
export const green50 = '#171D18';
export const green100 = '#1A211C';
export const green200 = '#1E2B20';
export const green300 = '#243B2B';
export const green400 = '#3C6348';
export const green500 = greenBase;
export const green600 = '#79B88D';
export const green700 = '#94C7A4';
export const green800 = '#ABD4B9';

// Coral ✨
export const coralBase = '#AD3836';
export const coral50 = '#361D1A';
export const coral100 = '#471F1D';
export const coral200 = '#732423';
export const coral300 = '#912927';
export const coral400 = '#A13230';
export const coral500 = coralBase;
export const coral600 = '#B24644';
export const coral700 = '#C75E5B';
export const coral800 = '#C57D7C';

// Purple ✨
export const purpleBase = '#484687';
export const purple50 = '#202023';
export const purple100 = '#22232A';
export const purple200 = '#242430';
export const purple300 = '#272740';
export const purple400 = '#302F52';
export const purple500 = purpleBase;
export const purple600 = '#7671C3';
export const purple700 = '#A09BDE';
export const purple800 = '#B9B6F2';

// Purple Alpha ✨
const purpleAlphaBase = '#8E8EEA';
export const purpleLightAlpha50 = alpha(purpleAlphaBase, 0.25);
export const purpleLightAlpha100 = alpha(purpleAlphaBase, 0.35);
export const purpleLightAlpha500 = alpha(purpleAlphaBase, 0.5);
export const purpleLightAlpha700 = alpha(purpleAlphaBase, 0.6);
export const purpleLightAlpha900 = alpha(purpleAlphaBase, 0.9);

// Yellow ✨
export const yellowBase = '#E5AC19';
export const yellow50 = '#302B1F';
export const yellow100 = '#423615';
export const yellow200 = '#76590D';
export const yellow300 = '#A37C18';
export const yellow400 = '#C79516';
export const yellow500 = yellowBase;
export const yellow600 = '#F0C454';
export const yellow700 = '#F2C95E';
export const yellow800 = '#FFE194';

// Sand ✨
export const sandBase = '#706961';
export const sand50 = '#292522';
export const sand100 = '#302E2B';
export const sand200 = '#423D37';
export const sand300 = '#504A43';
export const sand400 = '#615A52';
export const sand500 = sandBase;
export const sand600 = '#817B75';
export const sand700 = '#918C86';
export const sand800 = '#A29D98';

// Neutral Alpha ✨
const neutralAlphaBase = '#2C2C2D';
export const neutralAlpha50 = alpha(neutralAlphaBase, 0.25);
export const neutralAlpha100 = alpha(neutralAlphaBase, 0.35);
export const neutralAlpha500 = alpha(neutralAlphaBase, 0.5);
export const neutralAlpha700 = alpha(neutralAlphaBase, 0.6);
export const neutralAlpha900 = alpha(neutralAlphaBase, 0.95);
