import {
  blackAlpha700,
  blue100,
  blue50,
  blue500,
  gray600,
  green100,
  green500,
  green400,
  coral100,
  coral600,
  coral800,
  purple50,
  whiteBase,
  yellow100,
  yellow500,
  blue200,
  gray1050,
  gray1100,
  gray1300,
  coral200,
  yellow800,
  coral700,
  blackBase,
  gray1400,
  gray1200,
  gray1000,
  gray700,
  gray900,
  yellow700,
  gray800,
  green300,
  neutralAlpha700,
  neutralAlpha900,
  gray100,
  blue700,
  coral300,
  purple100,
  blue600,
  purple800,
  whiteAlpha300,
  whiteAlpha700,
  whiteAlpha800,
  purple500,
  purple200,
  purpleLightAlpha500,
  yellow50,
  green800,
  gray400,
  green200,
  purple300,
  coral50,
  purple700,
  purple600,
  gray200,
} from '../darkPalette';
import type { Colors } from '../colors.types';

/** IMPORTANT! Any changes to dark needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in colorTokenToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the dark theme class framework colors in globals.css
 */
export const dark: Colors = {
  bg: gray1300,
  bgStrong: gray1400,
  bgOverlay: blackAlpha700,
  border: gray900,
  borderBrandWeakest: blue100,
  borderDisabled: gray1100,
  borderInfoWeakest: purple100,
  borderNeutralWeakest: gray1050,
  borderNegativeWeakest: coral100,
  borderNoticeWeakest: yellow100,
  borderPositiveWeakest: green100,
  borderPrimaryWeakest: gray800,
  borderSecondaryWeakest: purple100,
  borderStrong: gray700,
  borderTransparent: whiteAlpha300,
  borderWeak: gray1050,
  borderWeaker: gray1100,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  disabled: gray1000,
  disabledStrong: gray900,
  highContrast: blackBase,
  info: purple500,
  infoWeak: purple200,
  infoWeakest: purple50,
  interactiveFocused: purpleLightAlpha500,
  negative: coral600,
  negativeWeak: coral100,
  negativeWeakest: coral50,
  neutral: gray1000,
  neutralWeak: gray1100,
  neutralWeakest: gray1100,
  notice: yellow500,
  noticeWeak: yellow100,
  noticeWeakest: yellow50,
  onBrand: whiteBase,
  onBrandWeak: gray400,
  onBrandWeakest: gray400,
  onDisabled: gray700,
  onDisabledStrong: gray700,
  onHighContrast: gray100,
  onInfo: whiteBase,
  onInfoWeak: purple800,
  onInfoWeakest: purple800,
  onNegative: whiteBase,
  onNegativeWeak: coral800,
  onNegativeWeakest: coral800,
  onNeutral: whiteBase,
  onNeutralWeak: whiteBase,
  onNeutralWeakest: whiteBase,
  onNotice: gray1400,
  onNoticeWeak: yellow700,
  onNoticeWeakest: yellow800,
  onPositive: gray1300,
  onPositiveWeak: green800,
  onPositiveWeakest: green800,
  onPrimary: gray1300,
  onPrimaryWeak: gray1300,
  onPrimaryWeakest: gray1300,
  onSecondary: whiteBase,
  onSecondaryWeak: purple800,
  onSecondaryWeakest: purple800,
  positive: green400,
  positiveWeak: green200,
  positiveWeakest: green100,
  primary: gray100,
  primaryWeak: gray600,
  primaryWeakest: gray700,
  secondary: purple500,
  secondaryWeak: purple300,
  secondaryWeakest: purple200,
  surface: gray1200,
  surfaceRaised: gray1100,
  surfaceSunken: gray1400,
  surfaceOverlay: gray1200,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  text: gray100,
  textDisabled: gray900,
  textLink: whiteBase,
  textNegative: coral700,
  textPrimary: gray100,
  textWeak: whiteAlpha800,
  textWeaker: whiteAlpha700,

  // COMPONENT TOKENS
  inputBg: gray1100,
  inputBgActive: gray1200,

  buttonBgBrand: blue500,
  buttonBgBrandHover: blue600,
  buttonBgBrandActive: blue700,

  buttonBgNegative: coral600,
  buttonBgNegativeHover: coral300,
  buttonBgNegativeActive: coral200,
  buttonBgNegativeWeak: coral100,
  buttonBgNegativeWeakHover: coral200,
  buttonBgNegativeWeakActive: coral300,

  buttonBgOutlineHover: gray1000,
  buttonBgOutlineActive: gray900,
  buttonBgOutlineChecked: purple100,
  buttonBgOutlineCheckedHover: purple100,
  buttonBgOutlineCheckedActive: purple50,
  buttonBgPositive: green500,
  buttonBgPositiveHover: green400,
  buttonBgPositiveActive: green300,

  buttonBgPrimary: gray200,
  buttonBgPrimaryActive: gray700,
  buttonBgPrimaryHover: gray400,
  buttonBgPrimaryChecked: purple700,
  buttonBgPrimaryCheckedActive: purple500,
  buttonBgPrimaryCheckedHover: purple600,

  buttonBgPrimaryReverse: gray1200,
  buttonBgPrimaryReverseHover: gray1300,
  buttonBgPrimaryReverseActive: gray1400,
  buttonBgPrimaryReverseChecked: purple500,
  buttonBgPrimaryReverseCheckedHover: purple300,
  buttonBgPrimaryReverseCheckedActive: purple200,

  buttonBgSecondaryHover: gray1100,
  buttonBgSecondaryActive: gray1000,
  buttonBgSecondaryChecked: purple100,
  buttonBgSecondaryCheckedHover: purple200,
  buttonBgSecondaryCheckedActive: purple300,

  buttonBgTertiary: gray1000,
  buttonBgTertiaryActive: gray1200,
  buttonBgTertiaryHover: gray1100,
  buttonBgTertiaryChecked: purple700,
  buttonBgTertiaryCheckedActive: purple500,
  buttonBgTertiaryCheckedHover: purple600,

  surfaceHover: gray1050,
  surfaceActive: gray1200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purple200,
};
