import React from 'react';

export type ConfidenceFeatureOverrides = Record<string, string>;

export interface ConfidenceOverrideContextT {
  values: ConfidenceFeatureOverrides;
  set: (splitName: string, value: string) => void;
  reset: (splitName: string) => void;
  clearAll: () => void;
  fromTreatments: <T extends Record<string, string>>(treatments: T) => T;
}

const noop = () => {};

const defaultOverrideContext: ConfidenceOverrideContextT = {
  values: {},
  set: noop,
  reset: noop,
  clearAll: noop,
  fromTreatments: ((t) => t) as ConfidenceOverrideContextT['fromTreatments'],
};

export const ConfidenceOverridesContext =
  React.createContext<ConfidenceOverrideContextT>(defaultOverrideContext);

ConfidenceOverridesContext.displayName = 'ConfidenceOverridesContext';

export const useConfidenceOverrides = () =>
  React.useContext(ConfidenceOverridesContext);
